<template>
  <!-- full page background color -->
  <v-card flat tile class="mb-n15">
    <v-tabs v-model="tab" centered color="primary">
      <v-tab class="text-capitalize">Questions</v-tab>
      <v-tab class="text-capitalize">Settings</v-tab>
      <v-tab class="text-capitalize">Response</v-tab>
    </v-tabs>
    <!-- full page background color -->
    <v-card fluid flat tile min-height="92vh" class="back pa-6  px-md-15">
      <v-tabs-items v-model="tab" class="transparent">
        <v-tab-item>
          <!-- to center the card in different media screen -->
          <v-row class="justify-center">
            <!-- loader before the data is ready -->
            <v-col v-if="loader" xl="6" lg="8" md="10">
              <v-skeleton-loader
                class="mb-3"
                type="card-heading, list-item-two-line"
              />
              <v-skeleton-loader class="mb-3" type="article, actions" />
              <div class="d-flex">
                <v-spacer></v-spacer>
                <v-skeleton-loader type="button" />
              </div>
            </v-col>
            <!-- to wrap in the column and leave space both side -->
            <v-col v-else xl="6" lg="8" md="10">
              <!-- Form Header shows Form name and desc-->
              <v-card elevation="1" class="customForm">
                <!-- card don't allow CSS border that is required to identify when focus -->
                <div class="topborder">
                  <!-- Textfield for form name -->
                  <v-card-title class="pb-0 pt-0">
                    <v-text-field
                      v-model="formName"
                      class="h2text mb-0"
                      single-line
                      hide-details
                    />
                  </v-card-title>
                  <!-- Textfield for form desc -->
                  <v-card-text>
                    <v-text-field
                      v-model="formDesc"
                      placeholder="Form Description"
                      single-line
                      hide-details
                    />
                  </v-card-text>
                </div>
              </v-card>
              <!-- Draggable Fields to reorder questions-->
              <draggable
                v-model="questionSet"
                class="list-group styleNone"
                tag="ul"
                handle=".handle"
                v-bind="dragOptions"
                @start="drag = true"
                @end="drag = false"
                @change="log"
              >
                <!-- for animation of the questions transition-group is required -->
                <transition-group
                  type="transition"
                  :name="!drag ? 'flip-list' : null"
                >
                  <!-- the set is displayed in a list -->
                  <li
                    v-for="(question, i) in questionSet"
                    :key="i"
                    class="list-group-item"
                  >
                    <!-- the card having the fields -->
                    <v-card
                      elevation="1"
                      class="my-2 customForm"
                      :ripple="false"
                      @click="cardFocus = i"
                    >
                      <!-- card don't allow CSS border that is required to identify when focus -->
                      <div :class="cardFocus == i ? 'onfocus' : null" ca>
                        <!-- the handle to reorder the questions. So clicking anywhere won't work -->
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              block
                              depressed
                              tile
                              class="handle inverted"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon>mdi-reorder-horizontal</v-icon>
                            </v-btn>
                          </template>
                          <span>Reorder</span>
                        </v-tooltip>
                        <!-- when focus some options appears and a left border -->
                        <v-card-text
                          class="py-0"
                          :class="cardFocus != i ? 'mb-7' : 'mb-3'"
                        >
                          <!-- The question and datatype appears in this row -->
                          <v-row class="my-0">
                            <!-- question -->
                            <v-col cols="6" md="8">
                              <v-text-field
                                v-model="question.name"
                                placeholder="Question"
                                single-line
                                hide-details
                                :filled="questionFocus == i"
                                @focus="questionFocus = i"
                                @blur="questionFocus = -1"
                              />
                            </v-col>
                            <!-- datatype (mcq, checkbox, text)-->
                            <v-col v-if="cardFocus == i" cols="6" md="4">
                              <v-select
                                v-model="question.datatype"
                                :items="datatypes"
                                single-line
                                hide-details
                                dense
                                height="48"
                                :menu-props="{ maxHeight: 416 }"
                                outlined
                              >
                                <template v-slot:selection="{ item }">
                                  <v-icon class="mr-2">{{ item.icon }}</v-icon>
                                  <span class="text-body-2">{{
                                    item.name
                                  }}</span>
                                </template>
                                <template v-slot:item="{ item }">
                                  <v-divider
                                    v-if="item.name == 'divider'"
                                  ></v-divider>
                                  <div v-else>
                                    <v-icon class="mr-2">{{
                                      item.icon
                                    }}</v-icon>
                                    <span class="text-body-2">{{
                                      item.name
                                    }}</span>
                                  </div>
                                </template>
                              </v-select>
                            </v-col>
                          </v-row>
                          <!-- if the datatype is text this row is displayed to understand -->
                          <v-row
                            v-if="question.datatype == 'text'"
                            class="my-0"
                          >
                            <v-col cols="7">
                              <p class="my-0 dottedLine text--disabled">
                                Short answer text
                              </p>
                            </v-col>
                          </v-row>
                          <!-- if the datatype is date this row is displayed to understand -->
                          <v-row
                            v-else-if="question.datatype == 'date'"
                            class="my-0"
                          >
                            <v-col cols="9" class="d-flex">
                              <p class="my-0 dottedLine text--disabled">
                                Day, Month, Year
                                <v-icon class=" pl-5 text--disabled"
                                  >mdi-calendar</v-icon
                                >
                              </p>
                            </v-col>
                          </v-row>
                          <!-- if the datatype is time this row is displayed to understand -->
                          <v-row
                            v-else-if="question.datatype == 'time'"
                            class="my-0"
                          >
                            <v-col cols="7" class="d-flex">
                              <p class="my-0 dottedLine text--disabled">
                                Time
                                <v-icon class=" pl-5 text--disabled"
                                  >mdi-clock-outline</v-icon
                                >
                              </p>
                            </v-col>
                          </v-row>
                          <!-- if the datatype is mcq or checkbox or dropdown, options will appear  -->
                          <v-list
                            v-else-if="
                              question.datatype == 'mcq' ||
                                question.datatype == 'checkbox' ||
                                question.datatype == 'dropdown'
                            "
                            :dense="cardFocus != i"
                            class="my-0"
                          >
                            <!-- the options of the questions are listed -->
                            <v-list-item
                              v-for="(option, j) in question.options"
                              :key="j"
                            >
                              <!-- if mcq circle will appear as icon or else square will appear -->
                              <v-list-item-icon
                                v-if="
                                  question.datatype == 'mcq' ||
                                    question.datatype == 'checkbox'
                                "
                              >
                                <v-icon
                                  v-text="
                                    question.datatype == 'mcq'
                                      ? 'mdi-circle-outline'
                                      : 'mdi-checkbox-blank-outline'
                                  "
                                ></v-icon>
                              </v-list-item-icon>
                              <span class="text-body-1 pr-2">{{
                                question.datatype == "dropdown"
                                  ? j + 1 + "."
                                  : null
                              }}</span>
                              <!-- text field to change the label of the options -->
                              <v-list-item-content>
                                <v-text-field
                                  v-model="question.options[j]"
                                  class="ma-0 pa-0"
                                  hide-details
                                  single-line
                                  dense
                                  :append-outer-icon="
                                    cardFocus == i ? 'mdi-close' : null
                                  "
                                  @click:append-outer="removeOptions(i, j)"
                                />
                              </v-list-item-content>
                            </v-list-item>
                            <!-- to app more options to the question -->
                            <v-list-item v-if="cardFocus == i">
                              <v-list-item-icon
                                v-if="
                                  question.datatype == 'mcq' ||
                                    question.datatype == 'checkbox'
                                "
                              >
                                <v-icon
                                  v-text="
                                    question.datatype == 'mcq'
                                      ? 'mdi-circle-outline'
                                      : 'mdi-checkbox-blank-outline'
                                  "
                                ></v-icon>
                              </v-list-item-icon>
                              <span class="text-body-1 pr-2">{{
                                question.datatype == "dropdown"
                                  ? question.options.length + 1 + "."
                                  : null
                              }}</span>
                              <v-list-item-content>
                                <div>
                                  <span class="pt-1 mr-8">Add</span>
                                  <!-- btn to add new options -->
                                  <v-btn
                                    small
                                    depressed
                                    dark
                                    class="px-2 thumb"
                                    @click="addOption(i)"
                                    >Option</v-btn
                                  >
                                </div>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                          <!-- if datatype is file upload -->
                          <div
                            v-else-if="
                              question.datatype == 'file' && cardFocus == i
                            "
                          >
                            <v-row class="my-0">
                              <v-col cols="7" class="pa-0">
                                <div class="d-flex">
                                  <span class="pa-3 pb-0"
                                    >Allow only specific file types</span
                                  >
                                  <!-- switch to set mandatory question -->
                                  <v-switch
                                    v-model="question.allowfileType"
                                    class="ma-0 pa-0 pr-2 pt-3"
                                  />
                                </div>
                              </v-col>
                            </v-row>

                            <v-row v-if="question.allowfileType" class="my-0">
                              <v-col cols="3" class="py-0">
                                <v-checkbox
                                  v-model="question.filetype"
                                  label="Document"
                                  value="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                  hide-details
                                />
                                <v-checkbox
                                  v-model="question.filetype"
                                  label="Spreadsheet"
                                  value="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                  hide-details
                                />
                                <v-checkbox
                                  v-model="question.filetype"
                                  label="PDF"
                                  value="application/pdf"
                                  hide-details
                                />
                              </v-col>
                              <v-col cols="3" class="py-0">
                                <v-checkbox
                                  v-model="question.filetype"
                                  label="Presentation"
                                  value="application/vnd.openxmlformats-officedocument.presentationml.presentation"
                                  hide-details
                                />
                                <v-checkbox
                                  v-model="question.filetype"
                                  label="Image"
                                  value="image/*"
                                  hide-details
                                />
                                <v-checkbox
                                  v-model="question.filetype"
                                  label="Audio"
                                  value="audio/*"
                                  hide-details
                                />
                              </v-col>
                            </v-row>
                            <v-row>
                              <span class="pa-3 pb-0 text--disabled"
                                >Maximum file size: 10MB</span
                              >
                            </v-row>
                          </div>
                          <div
                            v-else-if="
                              question.datatype == 'file' && cardFocus != i
                            "
                          >
                            <v-row class="my-0">
                              <v-col cols="7">
                                <v-btn disabled outlined>
                                  <v-icon>mdi-upload</v-icon>
                                  <span class="text-capitalize">Add File</span>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </div>
                        </v-card-text>
                        <v-divider class="mx-5"></v-divider>
                        <!-- Action btns for utility -->
                        <v-card-actions v-if="cardFocus == i" class="d-flex">
                          <v-spacer></v-spacer>
                          <!-- btn to add next question -->
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                small
                                icon
                                fab
                                v-bind="attrs"
                                v-on="on"
                                @click="addQuestion(i)"
                              >
                                <v-icon>mdi-plus-circle-outline</v-icon>
                              </v-btn>
                            </template>
                            <span>Add</span>
                          </v-tooltip>
                          <!-- btn to duplicate question -->
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                small
                                icon
                                fab
                                v-bind="attrs"
                                v-on="on"
                                @click="duplicateQuestion(i)"
                              >
                                <v-icon
                                  >mdi-checkbox-multiple-blank-outline</v-icon
                                >
                              </v-btn>
                            </template>
                            <span>Duplicate</span>
                          </v-tooltip>
                          <!-- btn to delete question -->
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                small
                                icon
                                fab
                                v-bind="attrs"
                                v-on="on"
                                @click="removeQuestion(i)"
                              >
                                <v-icon>mdi-delete-outline</v-icon>
                              </v-btn>
                            </template>
                            <span>Delete</span>
                          </v-tooltip>

                          <v-divider vertical class="px-2 my-2"></v-divider>

                          <span class="pa-3">Required</span>
                          <!-- switch to set mandatory question -->
                          <v-switch
                            v-model="question.required"
                            class="ma-0 pa-0 pr-2 pt-3"
                          />
                        </v-card-actions>
                      </div>
                    </v-card>
                  </li>
                </transition-group>
              </draggable>
              <!-- display the save/update btn -->
              <div class="d-flex">
                <v-spacer></v-spacer>
                <v-btn class="primarygrad" dark depressed @click="save()">{{
                  formId ? "UPDATE" : "SAVE"
                }}</v-btn>
              </div>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item>
          <v-row class="justify-center">
            <!-- loader before the data is ready -->
            <v-col v-if="loader" xl="6" lg="8" md="10">
              <v-skeleton-loader
                class="mb-3"
                type="card-heading, list-item-two-line"
              />
              <v-skeleton-loader class="mb-3" type="article, actions" />
              <div class="d-flex">
                <v-spacer></v-spacer>
                <v-skeleton-loader type="button" />
              </div>
            </v-col>
            <v-col v-else xl="6" lg="8" md="10">
              <v-card flat outlined>
                <v-card-title class="font-weight-regular">
                  Settings</v-card-title
                >
                <v-divider class="mx-5"></v-divider>
                <v-card-text>
                  <v-expansion-panels accordion flat>
                    <v-expansion-panel>
                      <v-expansion-panel-header v-slot="{ open }">
                        <v-row no-gutters>
                          <v-col cols="4" class="my-0 py-2">
                            Question defaults
                          </v-col>
                          <v-col cols="8" class="text--secondary">
                            <v-fade-transition leave-absolute>
                              <v-row v-if="!open" no-gutters>
                                <v-switch
                                  v-model="requiredByDefault"
                                  class="my-0"
                                  inset
                                  disabled
                                  label="Required by Default"
                                />
                              </v-row>
                            </v-fade-transition>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div class="d-flex">
                          <p class="my-2">Make questions required by default</p>
                          <v-spacer></v-spacer>
                          <v-switch
                            v-model="requiredByDefault"
                            class="my-2"
                            @change="requiredDefault()"
                          ></v-switch>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header v-slot="{ open }">
                        <v-row no-gutters>
                          <v-col cols="4">
                            <p class="my-2 py-2">Lists Assigned</p>
                          </v-col>
                          <v-col cols="8" class="text--secondary">
                            <v-fade-transition leave-absolute>
                              <v-row
                                v-if="!open"
                                no-gutters
                                class="text--disabled"
                              >
                                <p class="my-2 py-2">
                                  {{ getListName(listId) }}
                                </p>
                              </v-row>
                            </v-fade-transition>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row>
                          <v-col cols="4">
                            <p class="my-2">Select Lists</p>
                          </v-col>
                          <v-col>
                            <v-autocomplete
                              v-model="listId"
                              :items="lists"
                              item-text="name"
                              item-value="id"
                              placeholder="Lists"
                              prepend-inner-icon="view_list"
                              autocomplete
                              multiple
                              hide-details
                              flat
                            />
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-card-text>
              </v-card>
              <!-- display the save/update btn -->
              <div class="d-flex mt-2">
                <v-spacer></v-spacer>
                <v-btn class="primarygrad" dark depressed @click="save()">{{
                  formId ? "UPDATE" : "SAVE"
                }}</v-btn>
              </div>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item>
          <Response :form-id="$route.params.id" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-card>
</template>

<script>
// import Questions from '../components/questions.vue';
import draggable from "vuedraggable";
import Response from "../components/response.vue";
export default {
  components: { draggable, Response },
  data() {
    return {
      tab: "",
      formName: "", // Name of the form is initialised
      formDesc: "", //Desc of the form is initialised
      questionSet: [], // where the field of questions are stored
      datatypes: [
        // Datatype options
        { name: "Short answer", icon: "mdi-text-short", value: "text" }, //Short Paragraph datatype
        { name: "divider", disabled: true },
        { name: "Multiple Choice", icon: "mdi-circle-slice-8", value: "mcq" }, // mcq datatype
        {
          name: "Checkbox",
          icon: "mdi-checkbox-intermediate",
          value: "checkbox",
        }, // checkbox datatype
        {
          name: "Dropdown",
          icon: "mdi-chevron-down-circle",
          value: "dropdown",
        }, // checkbox datatype
        // {name:"divider", 'disabled': true},
        // {name:"File Upload", icon:"mdi-cloud-upload", 'value':"file"}, //Attachment datatype
        { name: "divider", disabled: true },
        { name: "Date", icon: "mdi-calendar", value: "date" }, // Date datatype
        { name: "Time", icon: "mdi-clock-outline", value: "time" }, //Time datatype
      ],
      questionFocus: -1, // to store which question is focusing so that it is highlighted
      cardFocus: 0, // to store which card is focusing so that from the unfocused cards so elements can be hidden
      drag: false, // to check if a drag function is performed
      loader: true, //loader
      formId: "",
      requiredByDefault: false,
      listId: [],
      lists: [],
    };
  },
  computed: {
    // draggable options for animating the move
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },

  async created() {
    var url;

    if (this.$store.state.user.role == "Manager") {
      url = `${this.$store.state.ENDPOINT}/lists?filter[where][companyId]=${this.$store.state.user.companyId}`;
    } else {
      url = `${this.$store.state.ENDPOINT}/accounts/${this.$store.state.user.id}/lists`;
    }

    await this.$http.get(url).then((res) => {
      this.lists = res.body;
    });

    this.formId = this.$route.params.id;
    this.tab = this.$route.params.tab ? this.$route.params.tab - 1 : 0;
    if (this.formId) {
      //if the form is editing the information is collected.
      var id = this.formId; // the id of the form is stored
      await this.getForm(id); // the details of the form is received here.
    } else {
      // if the form is new a blank question is initialised and pushed in the questionSet
      var newQuestion = {
        name: "",
        datatype: "mcq",
        options: ["Option 1"],
        allowfileType: false,
        filetype: [],
        required: false,
      };
      this.formName = "Untitled form";
      this.questionSet.push(newQuestion);
    }
    this.loader = false;
  },
  methods: {
    async getForm(id) {
      // getting the details of the form
      this.$http
        .get(`${this.$store.state.ENDPOINT}/forms/${id}`)
        .then((res) => {
          console.log("res.data", res.data);
          // setting the details of the form
          this.formName = res.data.name;
          this.formDesc = res.data.desc;
          this.questionSet = res.data.fields;
          this.formId = res.data.id;
          this.listId = res.data.listsId;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        }); //if error occurs
    },
    // log when a card is moved
    log: function(evt) {
      // when the card is moved the index of the card focus is also changed to the focus remains constant
      this.cardFocus = evt.moved.newIndex;
    },
    // method to add a new Question below the card where the add button is clicked
    addQuestion(index) {
      // a new blank question is initialised
      var question = {
        name: "",
        datatype: "mcq",
        options: ["Option 1"],
        allowfileType: false,
        filetype: [],
        required: this.requiredByDefault,
      };
      // added right after the index where the button is clicked
      this.questionSet.splice(index + 1, 0, question);
    },
    // method to duplicate a Question below the card where the duplicate button is clicked
    duplicateQuestion(index) {
      // if we normally add object all the object will reflect if one is changed
      var question = JSON.parse(JSON.stringify(this.questionSet[index]));
      // dublicate object is added right after the index where the button is clicked
      this.questionSet.splice(index, 0, question);
    },
    // method to remove a Question where the remove button is clicked
    removeQuestion(index) {
      // checked if this is the last question
      if (this.questionSet.length == 1) {
        // in case of the last question it can not be deleted
        return this.$swal({
          type: "warning",
          text: "A Form must have atleast 1 question",
        });
      }
      // removed the question from its position
      this.questionSet.splice(index, 1);
    },
    // method to add a new Option in the question where the add Option button is clicked
    addOption(index) {
      // the length of the option set is calculated so that the name can be set as Option+len+1
      var len = this.questionSet[index].options.length + 1;
      // the new option is pushed at the end
      this.questionSet[index].options.push("Option " + len);
    },
    // method to remove the option from the question where the remove Option button is clicked
    removeOptions(index, optionIndex) {
      // checked if this is the last option in that question
      if (this.questionSet[index].options.length == 1) {
        // in case of the last option it can not be deleted
        return this.$swal({
          type: "warning",
          text: "A MCQ question must have atleast 1 option",
        });
      }
      // removed the option from its position
      this.questionSet[index].options.splice(optionIndex, 1);
    },
    //method to save the form
    save() {
      // create the question set with only the questions
      var questions = this.questionSet.map((q) => q.name);
      // set a flag to check if any question is empty
      var flag = 0;
      // checking if any question is empty
      questions.forEach((q) => (q == "" ? (flag = 1) : null));
      if (flag == 1) {
        // if any question is found empty an message is displayed
        return this.$swal({
          type: "warning",
          text: "Question Field can not be empty. Please check!",
        });
      }
      // a payload is initialised
      var payload = {};
      payload.name = this.formName; // form Name
      payload.desc = this.formDesc; // form Desc
      payload.createdOn = new Date().toISOString(); //form CreatedOn date
      payload.fields = this.questionSet; // the fieldSet
      payload.companyId = this.$store.state.user.companyId; // company ID
      payload.accountsId = this.$store.state.user.id; //account ID
      payload.listsId = this.listId; //list Ids where the form belongs

      if (!this.formId || this.formId == "") {
        // storing new form
        this.$http
          .post(`${this.$store.state.ENDPOINT}/forms`, payload) //storing the form in the database
          .then((res) => {
            // console.log("Form Response: ",res )
            this.$swal({
              type: "success",
              text: "New Form is created",
            }).then((yes) => {
              // after storing the message is displayed
              if (yes.value) {
                // on user click redirected to forms page
                this.$router.go(-1);
              }
            });
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.log(err);
          }); // in case of error in storing the form
      } else {
        // updating old form
        this.$http
          .patch(`${this.$store.state.ENDPOINT}/forms/${this.formId}`, payload) //updating the form in the database
          .then((res) => {
            // console.log("Form Response: ",res )
            this.$swal({
              type: "success",
              text: "Form is Updated",
            }).then((yes) => {
              // after updating the message is displayed
              if (yes.value) {
                // on user click redirected to forms page
                this.$router.go(-1);
              }
            });
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.log(err);
          }); // in case of error in storing the form
      }
    },
    requiredDefault() {
      if (this.requiredByDefault) {
        //if the switch is turned On all the questions are mandatory
        this.questionSet.map((q) => {
          q.required = true;
        });
      }
    },
    getListName(listId) {
      console.log("listId: ", listId);
      if (listId && listId.length >= 1) {
        //if it is a valid list of list Ids
        var lists = [];
        listId.forEach((id) => {
          //Name of each Ids are stored in lists
          var listObj = this.lists.find((l) => l.id == id);

          //  console.log("listObj", listObj);
          if (typeof listObj != "undefined") {
            lists.push(listObj.name);
          }
        });
        return lists.join(","); //The names are returned
      } else return "No list selected";
    },
  },
};
</script>

<style>
/* .v-menu__content{
    max-height: none !important;
} */
.customForm .v-text-field > .v-input__control > .v-input__slot:before {
  /* no underline always */
  border-color: inherit;
  border-style: none !important;
  border-width: thin 0 0;
}
.topborder {
  /* top border like alert */
  border-top: 8px solid #31a848;
}
.onfocus {
  /* to highlight the card focused on */
  border-left: 6px solid #92df48;
}
</style>
<style scoped>
.h2text {
  font-size: 1.5em !important;
  font-weight: 400 !important;
}
.dottedLine {
  border-bottom: 1px dotted rgba(0, 0, 0, 0.38);
}
ul.styleNone {
  /* no bullet in list */
  list-style-type: none;
  padding-left: 0;
}
</style>
