var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"back mb-n15 pa-6  px-md-15",attrs:{"fluid":"","flat":"","tile":"","min-height":"92vh"}},[_c('v-row',{staticClass:"justify-center"},[(_vm.loader)?_c('v-col',{attrs:{"xl":"6","lg":"8","md":"10"}},[_c('v-skeleton-loader',{staticClass:"mb-3",attrs:{"type":"card-heading, list-item-two-line"}}),_c('v-skeleton-loader',{staticClass:"mb-3",attrs:{"type":"article, actions"}}),_c('div',{staticClass:"d-flex"},[_c('v-spacer'),_c('v-skeleton-loader',{attrs:{"type":"button"}})],1)],1):_c('v-col',{attrs:{"xl":"6","lg":"8","md":"10"}},[_c('v-card',{attrs:{"flat":"","outlined":""}},[_c('v-card-title',{staticClass:"font-weight-regular"},[_vm._v(" Responses "),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.download()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-google-spreadsheet")])],1)]}}])},[_c('span',[_vm._v("Download Responses")])])],1),_c('v-divider',{staticClass:"mx-5"}),(!_vm.formId)?_c('v-card-text',[_c('span',[_vm._v("The form is not yet Active")])]):(_vm.ResponseDetails.length == 0)?_c('v-card-text',[_c('span',[_vm._v("No response submitted")])]):(_vm.ResponseDetails.length > 0)?_c('v-card-text',_vm._l((_vm.ResponseDetails),function(response,i){return _c('v-card',{key:i,staticClass:"my-3 pa-3",attrs:{"flat":"","outlined":""}},[_c('v-card-title',[_c('v-avatar',{staticStyle:{"position":"absolute"},attrs:{"size":"50","color":"primarygrad"}},[(typeof response.contacts != 'undefined')?_c('span',{staticClass:"white--text headline"},[_vm._v(" "+_vm._s(response.contacts.name ? response.contacts.name.substring(0, 2).toUpperCase() : "D")+" ")]):_c('span',{staticClass:"white--text headline"},[_vm._v(" "+_vm._s(typeof response.embeddedContact != "undefined" && typeof response.embeddedContact.name != "undefined" ? response.embeddedContact.name .substring(0, 2) .toUpperCase() : "D")+" ")])]),(typeof response.contacts != 'undefined')?_c('h4',{staticClass:"font-weight-regular pl-15"},[_vm._v(" "+_vm._s(response.contacts.name ? response.contacts.name : "Deleted")+" ")]):_c('h4',{staticClass:"font-weight-regular pl-15"},[_vm._v(" "+_vm._s(typeof response.embeddedContact != "undefined" ? response.embeddedContact.name : "Deleted")+" ")])],1),_vm._l((response.data),function(set,j){return _c('v-card-text',{key:j,staticClass:"pl-15 my-0 py-2 d-flex"},[(
                  response.questionSet[j] &&
                    response.questionSet[j].hasOwnProperty('name')
                )?_c('h4',{staticClass:"pr-3"},[_vm._v(" "+_vm._s(response.questionSet[j].name)+": ")]):_vm._e(),(
                  response.questionSet[j] &&
                    response.questionSet[j].hasOwnProperty('datatype')
                )?_c('span',[_vm._v(_vm._s(response.questionSet[j].datatype != "file" ? set : null))]):_vm._e(),(
                  response.questionSet[j] &&
                    response.questionSet[j].hasOwnProperty('datatype') &&
                    set != null &&
                    set.attachmentname != null &&
                    set.hasOwnProperty('attachmentname') &&
                    set.attachmentname != ''
                )?_c('a',{staticClass:"pointer",attrs:{"href":((_vm.$store.state.ENDPOINT) + "/storages/get/" + (set.attachmentname) + "?access_token=" + (_vm.$store.state.user.token)),"target":"_blank"}},[_vm._v(_vm._s(response.questionSet[j].datatype == "file" ? set.attachmentname : null))]):_vm._e()])})],2)}),1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }