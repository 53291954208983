<template>
  <!-- full page background color -->
  <v-card fluid flat tile min-height="92vh" class="back mb-n15 pa-6  px-md-15">
    <v-row class="justify-center">
      <!-- loader before the data is ready -->
      <v-col v-if="loader" xl="6" lg="8" md="10">
        <v-skeleton-loader
          class="mb-3"
          type="card-heading, list-item-two-line"
        />
        <v-skeleton-loader class="mb-3" type="article, actions" />
        <div class="d-flex">
          <v-spacer></v-spacer>
          <v-skeleton-loader type="button" />
        </div>
      </v-col>
      <!-- to wrap in the column and leave space both side -->
      <v-col v-else xl="6" lg="8" md="10">
        <v-card flat outlined>
          <v-card-title class="font-weight-regular">
            Responses
            <v-spacer></v-spacer>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click="download()">
                  <v-icon color="green">mdi-google-spreadsheet</v-icon>
                </v-btn>
              </template>
              <span>Download Responses</span>
            </v-tooltip>
          </v-card-title>
          <v-divider class="mx-5"></v-divider>
          <v-card-text v-if="!formId">
            <span>The form is not yet Active</span>
          </v-card-text>
          <v-card-text v-else-if="ResponseDetails.length == 0">
            <span>No response submitted</span>
          </v-card-text>
          <v-card-text v-else-if="ResponseDetails.length > 0">
            <v-card
              class="my-3 pa-3"
              flat
              outlined
              v-for="(response, i) in ResponseDetails"
              :key="i"
            >
              <v-card-title>
                <v-avatar
                  size="50"
                  color="primarygrad"
                  style="position: absolute; "
                >
                  <!-- <v-img v-if="item.picture" :src="getImage(item.picture)" /> -->

                  <!-- response.hasOwnProperty('embeddedContact') -->
                  <span
                    class="white--text headline"
                    v-if="typeof response.contacts != 'undefined'"
                  >
                    {{
                      response.contacts.name
                        ? response.contacts.name.substring(0, 2).toUpperCase()
                        : "D"
                    }}
                  </span>

                  <span class="white--text headline" v-else>
                    {{
                      typeof response.embeddedContact != "undefined" &&
                      typeof response.embeddedContact.name != "undefined"
                        ? response.embeddedContact.name
                            .substring(0, 2)
                            .toUpperCase()
                        : "D"
                    }}
                  </span>
                </v-avatar>
                <h4
                  class="font-weight-regular pl-15"
                  v-if="typeof response.contacts != 'undefined'"
                >
                  {{
                    response.contacts.name ? response.contacts.name : "Deleted"
                  }}
                </h4>

                <h4 class="font-weight-regular pl-15" v-else>
                  {{
                    typeof response.embeddedContact != "undefined"
                      ? response.embeddedContact.name
                      : "Deleted"
                  }}
                </h4>
              </v-card-title>
              <v-card-text
                class="pl-15 my-0 py-2 d-flex"
                v-for="(set, j) in response.data"
                :key="j"
              >
                <!-- {{ response.questionSet[j] }} -->
                <h4
                  class="pr-3"
                  v-if="
                    response.questionSet[j] &&
                      response.questionSet[j].hasOwnProperty('name')
                  "
                >
                  {{ response.questionSet[j].name }}:
                </h4>
                <span
                  v-if="
                    response.questionSet[j] &&
                      response.questionSet[j].hasOwnProperty('datatype')
                  "
                  >{{
                    response.questionSet[j].datatype != "file" ? set : null
                  }}</span
                >

                <a
                  v-if="
                    response.questionSet[j] &&
                      response.questionSet[j].hasOwnProperty('datatype') &&
                      set != null &&
                      set.attachmentname != null &&
                      set.hasOwnProperty('attachmentname') &&
                      set.attachmentname != ''
                  "
                  :href="
                    `${$store.state.ENDPOINT}/storages/get/${set.attachmentname}?access_token=${$store.state.user.token}`
                  "
                  class="pointer"
                  target="_blank"
                  >{{
                    response.questionSet[j].datatype == "file"
                      ? set.attachmentname
                      : null
                  }}</a
                >
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import * as XLSX from "xlsx/xlsx.mjs";
import Blob from "blob";
import * as fs from "../js/filesaver";
export default {
  props: {
    formId: {
      type: String,
    },
  },
  data() {
    return {
      formDetails: "",
      ResponseDetails: "",
      loader: true,
    };
  },
  async created() {
    if (this.formId) {
      await this.getForm(this.formId); // the details of the form is received here.
      await this.getFormResponse(this.formId);
    }

    this.loader = false;
  },
  methods: {
    getForm(id) {
      // getting the details of the form
      this.$http
        .get(`${this.$store.state.ENDPOINT}/forms/${id}`)
        .then((res) => {
          // setting the details of the form
          this.formDetails = res.data;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log("Error in Form: ", err);
        }); //if error occurs
    },
    getFormResponse(formsId) {
      // getting the details of the formResponse
      const self = this;
      var filter = {
        include: {
          relation: "formResponses",
          scope: {
            include: ["contacts"],
          },
        },
      };
      this.$http
        .get(
          `${
            this.$store.state.ENDPOINT
          }/forms/${formsId}?filter=${encodeURIComponent(
            JSON.stringify(filter)
          )}`
        )
        .then((res) => {
          // setting the details of the form
          console.log(res);
          self.ResponseDetails = res.body.formResponses;
          console.log("Response details are ", self.ResponseDetails);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log("Error in Response: ", err);
        }); //if error occurs
    },
    get(set) {
      console.log(set);
    },
    download() {
      const data = JSON.parse(JSON.stringify(this.ResponseDetails));

      data.map((d) => {
        for (let i = 0; i < d.data.length; i++) {
          if (d.questionSet[i] && d.questionSet[i].hasOwnProperty("name")) {
            var vn = d.questionSet[i].name;
            if (d.data[i] != null) {
              d[vn] = d.data[i].toString();
            }
          }
        }

        if (typeof d.created_On != "undefined") {
          d.created_On = d.createdOn.substring(0, 10);
        } else {
          d.created_On = "";
        }

        if (typeof d.contacts != "undefined") {
          d.contactName = d.contacts.name;
          d.contactPhone = d.contacts.phone;
        } else {
          if (typeof d.embeddedContact != "undefined") {
            d.contactName = d.embeddedContact.name;
            d.contactPhone = d.embeddedContact.phone;
          } else {
            d.contactName = "Deleted";
            d.contactPhone = "Deleted";
          }
        }

        delete d.id;
        delete d.accountsId;
        delete d.companyId;
        delete d.contactId;
        delete d.formsId;
        delete d.createdOn;
        delete d.contacts;
        delete d.data;
        delete d.questionSet;
        delete d.embeddedContact;
      });
      console.log("data: ", data);
      const EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const datanew = new Blob([excelBuffer], { type: EXCEL_TYPE });
      fs.saveAs(
        datanew,
        "FormResponse-" + new Date().toISOString().substr(0, 10) + ".xlsx"
      );
    },
  },
};
</script>

<style></style>
